import React from 'react';
import SsoHelpers from './sso_helpers.js';
import history from '../thewatch/components/history';
import createReactClass from 'create-react-class';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';

const Setup = createReactClass({
  mixins: [SsoHelpers],

  getInitialState() {
    return {
      logged_in: false,
      email: '',
      setup_version: null,
      sso_token: null,
      loading: true
    };
  },
  componentDidMount() {
    return $.ajax({
      url: 'setup.json',
      success: (res) => {
        return this.setState({
          logged_in: res.logged_in,
          email: res.email ? res.email : null,
          setup_version: res.setup_version,
          sso_token: res.token,
          loading: false,
          availableSlots: res.available_slots
        });
      }
    });
  },

  getSsoToken() {
    if (this.state.sso_token === null) {
      location.href = '/';
      return false;
    } else {
      return this.state.sso_token;
    }
  },
  useCurrentAccount(ev) {
    ev.preventDefault();
    return (location.href = '/auth/configuration/waiting/' + this.getSsoToken());
  },
  goToLogin(ev) {
    ev.preventDefault();
    return history.push('/login/' + this.getSsoToken());
  },
  goToSignup(ev) {
    ev.preventDefault();
    return history.push('/signup/' + this.getSsoToken());
  },
  render() {
    return (
      !this.state.loading && (
        <div className="session-container">
          <div className="top-icon">
            <figure>
              <NewIcoPrey />
            </figure>
          </div>
          <div className="flex-column gap-24">
            <h1 className="login-newui__h1">
              {I18n.t('node_config.configuring_title', {version: this.state.setup_version})}
            </h1>
            {this.state.logged_in ? (
              <div className="login-newui__desc">
                <span
                  className="login-newui__p"
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('node_config.configuring_desc', {
                      email: this.state.email
                    })
                  }}
                />
              </div>
            ) : (
              <div>{I18n.t('node_config.configuring_subtitle')}</div>
            )}
          </div>
          <form className="form form-session">
            <div className="login-newui__link-container flex-column gap-24">
              {this.state.logged_in ? (
                <button className="button button--primary expanded" onClick={this.useCurrentAccount}>
                  <span>{I18n.t('node_config.existing_user.title_2')}</span>
                </button>
              ) : (
                <button className="button button--primary expanded" onClick={this.goToLogin}>
                  <span>{I18n.t('node_config.existing_user.title')}</span>
                </button>
              )}
              <button className="button button--secondary expanded" onClick={this.goToSignup}>
                <span>
                  {this.state.logged_in ? I18n.t('node_config.new_user.title_2') : I18n.t('node_config.new_user.title')}
                </span>
              </button>
            </div>
          </form>
        </div>
      )
    );
  }
});

export default Setup;
