import React from 'react';

const NewIcoPrey = ({width = 168, height = 46}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 168 46" fill="none">
      <g clip-path="url(#clip0_12033_2665)">
        <path
          d="M167.77 11.9165V33.5965H142.99V30.4965H164.67V27.3965H142.99V11.9065H146.09V24.2965H164.67V11.9065H167.77V11.9165Z"
          fill="#122F48"
        />
        <path
          d="M59.37 11.9165V33.5965H62.47V27.4065H84.15V11.9265H59.37V11.9165ZM81.05 24.3065H62.47V15.0165H81.05V24.3065Z"
          fill="#122F48"
        />
        <path d="M112.02 11.9165V15.0165H90.34V27.4065H87.24V11.9165H112.02Z" fill="#122F48" />
        <path
          d="M115.12 11.9165V27.3965H139.9V24.2965H118.22V21.1965H139.9V11.9065H115.12V11.9165ZM136.8 18.1065H118.22V15.0065H136.8V18.1065Z"
          fill="#122F48"
        />
        <path
          d="M24.1 0.276489L16.56 4.62649L21.58 7.52649L24.09 6.07649L26.61 7.52649L31.63 4.62649L24.1 0.276489Z"
          fill="#3B79B9"
        />
        <path
          d="M24.1 45.2365L42.94 34.3565V22.7565L37.91 25.6565V31.4565L24.09 39.4365L10.27 31.4565V25.6565L5.25 22.7565V34.3565L24.09 45.2365H24.1Z"
          fill="#3B79B9"
        />
        <path
          d="M45.45 9.69647V15.4965L27.86 25.6465L32.88 28.5465L24.09 33.6265L15.3 28.5465L20.32 25.6465L2.74001 15.5065V9.71647L0.230011 8.26647V1.00647L21.58 13.3365L24.1 11.8865L26.61 13.3365L47.96 1.00647V8.25647L45.45 9.70647V9.69647Z"
          fill="#122F48"
        />
      </g>
      <defs>
        <clipPath id="clip0_12033_2665">
          <rect width="167.54" height="44.96" fill="white" transform="translate(0.230011 0.276489)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewIcoPrey;
