import React from 'react';
import {default as Typist} from 'react-typist';
import history from '../thewatch/components/history';
import ButtonNewUI from '../thewatch/new-ui/components/micro-components/button.js';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';

export default class Configured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: null,
      loading: true,
      email: null
    };
  }

  componentDidMount() {
    return $.ajax({
      url: 'configured.json',
      success: (res) => {
        return this.setState({
          device: res.device,
          email: res.email,
          loading: false
        });
      }
    });
  }

  goToDashboard(ev) {
    ev.preventDefault();
    return history.push('/dashboard/');
  }

  render() {
    return (
      <div className="session-container">
        <div className="configured--logo">
          <div className="top-icon">
            <figure>
              <NewIcoPrey />
            </figure>
          </div>
        </div>
        <div className="configured--content">
          <div className="configured--content__title">
            {I18n.t('node_config.configured.title', {email: this.state.email})}
          </div>
          <div className="configured--content__description">
            {I18n.t('node_config.configured.desc_1')}
            <b>{I18n.t('node_config.configured.desc_2')}</b>
            {I18n.t('node_config.configured.desc_3')}
            <b>{I18n.t('node_config.configured.desc_4')}</b>
            {I18n.t('node_config.configured.desc_5')}
            <b>{I18n.t('node_config.configured.desc_6')}</b>
            {I18n.t('node_config.configured.desc_7')}
          </div>
        </div>
        <div className="configured--button">
          <button className="button button--primary expanded" onClick={this.goToDashboard}>
            {I18n.t('node_config.configured.button')}
          </button>

          <a href="https://support.preyproject.com/hc/en-us/articles/17236962523661-How-to-uninstall-Prey-for-Computers">
            {I18n.t('node_config.configured.need_help')}
          </a>
        </div>
      </div>
    );
  }
}
