import React from 'react';

const SuccessVerifyEmail = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="237" height="217" viewBox="0 0 237 217" fill="none">
        <path opacity="0.18" d="M129.78 8.18097C129.239 8.50579 127.452 9.53439 124.854 12.3495C108.45 30.1064 113.81 50.4078 113.81 50.4078C114.676 53.8726 116.895 60.8562 123.446 67.8399C136.493 69.0309 149.486 70.2761 162.533 71.4671C165.131 70.7633 171.195 68.7061 175.796 62.9134C182.401 54.6305 181.048 44.7234 180.56 41.1504C179.315 31.7305 174.281 25.5589 171.465 22.1483C165.51 14.894 158.852 11.4292 156.957 10.5089C154.196 9.1013 139.795 2.06349 129.78 8.18097Z" fill="#224356"/>
        <g opacity="0.2">
          <path d="M235.618 124.684C231.503 101.675 211.743 81.5905 188.464 79.6957C175.796 78.6671 163.02 82.6733 150.352 81.1574C139.741 79.8581 129.672 74.5527 122.634 66.5404C112.185 54.6844 108.667 37.7396 97.6226 26.4249C82.356 10.8335 55.7747 10.5628 37.2057 22.0398C18.6909 33.5169 7.48455 54.1972 2.34154 75.4189L2.12499 81.8612C1.04225 103.787 6.8349 129.339 19.5571 148.937C32.4958 168.751 40.9412 176.98 64.4908 191.218C84.4673 203.345 123.608 208.65 146.996 209.246C170.383 209.787 194.365 202.857 211.96 187.428C229.5 171.999 239.732 147.692 235.618 124.684Z" fill="#98A4AC"/>
        </g>
        <path d="M37.5306 171.675C55.2875 186.292 72.2865 194.358 83.9801 198.852C93.9954 202.695 124.366 213.306 159.285 208.975C181.914 206.16 196.098 198.364 199.129 196.632C203.515 194.142 206.925 191.76 209.199 190.027" stroke="#122F48" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.9637 21.5527C37.9637 21.5527 -3.34283 45.5354 2.17914 98.8062" stroke="#122F48" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.04531 104.869C3.91239 104.869 4.61529 104.167 4.61529 103.299C4.61529 102.432 3.91239 101.729 3.04531 101.729C2.17824 101.729 1.47534 102.432 1.47534 103.299C1.47534 104.167 2.17824 104.869 3.04531 104.869Z" fill="#122F48"/>
        <path d="M213.801 187.158C214.668 187.158 215.371 186.455 215.371 185.588C215.371 184.72 214.668 184.018 213.801 184.018C212.933 184.018 212.231 184.72 212.231 185.588C212.231 186.455 212.933 187.158 213.801 187.158Z" fill="#122F48"/>
        <path d="M3.74908 107.793C3.74908 107.793 7.64695 131.451 18.7992 147.746" stroke="#122F48" stroke-width="2.4114" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.9074 156.245C18.7992 156.245 18.7992 156.029 18.9074 156.029C20.6398 155.866 23.9963 155.487 24.3753 154.513C24.7542 153.484 25.5121 149.478 25.837 147.8C25.837 147.692 26.0535 147.692 26.0535 147.8C26.2701 149.695 26.8656 154.405 27.5694 154.838C28.219 155.271 31.2507 155.704 32.6582 155.921C32.7665 155.921 32.7665 156.137 32.6582 156.137C31.5214 156.354 30.3845 156.57 29.3017 156.949C28.7604 157.112 28.1107 157.328 27.6776 157.707C27.1363 158.14 27.028 158.681 26.8656 159.331C26.5408 160.793 26.3242 162.309 26.1077 163.77C26.1077 163.879 25.8911 163.879 25.8911 163.77C25.6746 162.146 25.1332 158.681 23.8339 157.707C22.8594 156.949 20.315 156.462 18.9074 156.245Z" fill="#122F48"/>
        <path d="M194.42 82.7277C194.311 82.7277 194.311 82.5112 194.42 82.5112C196.152 82.3488 199.508 81.9698 199.887 80.9954C200.266 79.9668 201.024 75.9606 201.349 74.2824C201.349 74.1741 201.566 74.1741 201.566 74.2824C201.782 76.1772 202.378 80.8871 203.081 81.3202C203.731 81.7533 206.763 82.1864 208.17 82.4029C208.279 82.4029 208.279 82.6195 208.17 82.6195C207.033 82.836 205.897 83.0526 204.814 83.4315C204.272 83.5939 203.623 83.8105 203.19 84.1894C202.648 84.6225 202.54 85.1639 202.378 85.8136C202.053 87.2753 201.836 88.7911 201.62 90.2528C201.62 90.3611 201.403 90.3611 201.403 90.2528C201.187 88.6287 200.645 85.1639 199.346 84.1894C198.372 83.4315 195.881 82.9443 194.42 82.7277Z" fill="#122F48"/>
        <path d="M29.1394 164.745C29.0852 164.745 29.0852 164.637 29.1394 164.637C29.8431 164.583 31.1966 164.42 31.359 164.041C31.5214 163.608 31.8462 162.038 31.9545 161.335C31.9545 161.28 32.0086 161.28 32.0628 161.335C32.171 162.092 32.3876 163.987 32.6583 164.15C32.9289 164.312 34.12 164.474 34.7155 164.583C34.7696 164.583 34.7696 164.637 34.7155 164.691C34.2824 164.799 33.7951 164.853 33.362 165.016C33.1455 165.07 32.8748 165.178 32.7124 165.341C32.4958 165.503 32.4417 165.72 32.3876 165.99C32.2793 166.586 32.171 167.181 32.0628 167.777C32.0628 167.831 32.0086 167.831 31.9545 167.777C31.9003 167.127 31.6838 165.72 31.1424 165.341C30.7093 165.016 29.6807 164.799 29.1394 164.745Z" fill="#122F48"/>
        <path d="M206.925 87.3834C206.871 87.3834 206.871 87.2751 206.925 87.2751C207.629 87.221 208.982 87.0585 209.145 86.6796C209.307 86.2465 209.632 84.6765 209.74 83.9727C209.74 83.9186 209.794 83.9186 209.849 83.9727C209.957 84.7306 210.173 86.6254 210.444 86.7879C210.715 86.9503 211.906 87.1127 212.501 87.2209C212.555 87.2209 212.555 87.2751 212.501 87.3292C212.068 87.4375 211.581 87.4916 211.148 87.654C210.931 87.7082 210.661 87.8165 210.498 87.9789C210.282 88.1413 210.227 88.3578 210.173 88.6285C210.065 89.224 209.957 89.8195 209.849 90.415C209.849 90.4692 209.794 90.4692 209.74 90.415C209.686 89.7654 209.47 88.3578 208.928 87.9789C208.495 87.7082 207.521 87.4916 206.925 87.3834Z" fill="#122F48"/>
        <path d="M85.6042 16.6265H26.4325V75.7982H85.6042V16.6265Z" fill="white"/>
        <path d="M85.6042 75.7979C85.6042 75.7979 85.6042 75.6896 85.6042 75.5272C85.6042 75.3106 85.6042 75.04 85.6042 74.7151C85.6042 73.9572 85.6042 72.9286 85.6042 71.521C85.6042 68.7059 85.5501 64.6457 85.5501 59.4485C85.5501 49.1083 85.4959 34.3289 85.4418 16.6262L85.6583 16.8427C68.4428 16.8427 48.1414 16.8427 26.4866 16.8968L26.7032 16.6803C26.7032 38.3351 26.7032 58.6364 26.649 75.852L26.4325 75.6355C44.1353 75.6896 58.9147 75.6896 69.2548 75.7437C74.452 75.7437 78.5123 75.7979 81.3274 75.7979C82.7349 75.7979 83.7635 75.7979 84.5215 75.7979C84.9004 75.7979 85.1711 75.7979 85.3335 75.7979C85.117 75.7979 84.9004 75.7979 84.5215 75.7979C83.8177 75.7979 82.7349 75.7979 81.3815 75.7979C78.6205 75.7979 74.5603 75.852 69.3631 75.852C58.9688 75.852 44.1353 75.9061 26.3784 75.9603H26.1618V75.7437C26.1618 58.5282 26.1618 38.2268 26.1077 16.572V16.3555H26.3242C47.979 16.3555 68.2804 16.3555 85.4959 16.4096H85.7125V16.6262C85.6583 34.3831 85.6583 49.2166 85.6042 59.6109C85.6042 64.7539 85.5501 68.8142 85.5501 71.6293C85.5501 72.9827 85.5501 74.0655 85.5501 74.7693C85.5501 75.0941 85.5501 75.3648 85.5501 75.5813C85.6042 75.7437 85.6042 75.852 85.6042 75.7979Z" fill="#122F48"/>
        <path d="M59.3478 46.3475C62.6501 44.9399 65.0321 41.5293 65.0321 37.5232C65.0321 32.2719 61.0801 27.9409 56.0995 27.9409C51.1731 27.9409 47.1128 32.1636 47.1128 37.4149C47.1128 41.4752 49.4407 44.9399 52.7972 46.3475C39.0464 48.4047 39.6419 63.4006 39.6419 63.4006L72.1782 63.563C72.2865 63.5089 73.0444 48.4047 59.3478 46.3475Z" fill="#CD863F"/>
        <path d="M182.076 16.9512C181.697 16.5181 181.102 16.3557 180.506 16.2474C178.828 16.0309 177.096 16.4098 175.58 17.2219C177.799 15.0564 178.178 11.2668 176.554 8.61411C174.93 5.9614 171.628 4.4997 168.542 4.98694C165.456 5.47417 162.749 7.69378 161.558 10.563C160.367 13.4323 160.584 16.8429 161.937 19.6039L167.08 20.9573C169.462 22.5815 172.278 23.4476 175.147 23.4476C176.284 23.4476 177.529 23.2311 178.341 22.419C179.099 21.5529 178.991 19.9287 177.908 19.5498C178.991 19.7663 180.127 20.037 181.102 19.6039C182.076 19.1708 182.726 17.8174 182.076 16.9512Z" fill="#122F48"/>
        <path d="M142.881 78.1259L162.804 16.518C161.288 13.5405 158.581 11.2126 155.387 10.2381C152.193 9.26365 148.62 9.64261 145.75 11.2126C144.126 9.15538 141.365 8.0185 138.767 8.39746C136.168 8.77642 133.84 10.6712 132.92 13.1615C130.159 12.0246 126.64 13.2156 125.124 15.8142C123.608 18.4128 124.258 22.04 126.586 23.9348C124.312 24.8551 122.526 26.9123 121.876 29.2402C121.226 31.6222 121.714 34.2749 123.175 36.2239C120.739 37.4149 120.36 40.6631 120.685 43.3699C121.064 46.0227 121.822 48.8919 120.631 51.3281C119.71 53.1687 117.816 54.3597 116.516 55.9839C116.354 56.2004 116.192 56.417 116.083 56.6335C116.733 58.8531 118.086 62.2096 120.902 65.2954C122.742 67.2985 124.475 68.4895 125.99 69.464C129.184 71.467 134.706 74.6611 142.881 78.1259Z" fill="#122F48"/>
        <path d="M164.915 27.2909L165.186 23.9885L145.426 19.0079C139.308 17.9793 132.27 23.772 131.512 29.9436C130.646 36.7648 129.888 45.1561 130.43 50.3532C131.458 60.7475 139.254 62.534 139.254 62.534L138.658 72.7659L161.45 74.4442L164.915 27.2909Z" fill="#98A4AC"/>
        <path d="M139.254 62.5343C139.254 62.5343 146.508 63.4005 153.654 59.665C153.654 59.665 149.757 66.6487 139.146 65.0787L139.254 62.5343Z" fill="#526674"/>
        <path d="M173.793 77.5302C171.195 75.2023 170.545 71.521 172.223 68.3269C174.01 64.9163 178.99 63.6712 182.239 65.7284C183.646 66.5945 184.891 67.569 185.866 68.7059C188.194 65.0246 187.544 59.7733 185.054 56.092C184.133 54.7385 182.997 53.331 183.213 51.7069C183.376 50.7866 183.917 50.0286 184.35 49.2707C185.703 46.6721 185.216 43.2074 183.159 41.096C182.834 40.7712 182.509 40.4464 182.401 40.0133C182.347 39.7426 182.455 39.4719 182.509 39.1471C183.267 33.5169 180.615 29.7273 176.392 28.8069C176.121 28.7528 174.335 28.8069 174.118 28.6445C173.469 28.2114 174.281 27.1828 174.281 26.4791C174.226 24.476 173.252 20.9029 171.899 19.4954C168.109 15.5434 162.695 15.5975 161.125 16.7885C161.125 16.7885 159.339 17.3299 159.014 18.4126H157.606L140.932 16.3013C137.197 17.2216 133.84 19.4954 131.512 22.5271C129.184 25.5587 127.939 29.4024 127.993 33.2462C130.213 33.5169 132.379 32.5424 134.111 31.1348C135.843 29.7273 137.143 27.9408 138.442 26.1001C141.636 27.0204 145.263 26.046 147.537 23.6098C148.728 25.1798 150.731 26.1001 152.68 26.046C153.275 26.046 153.925 25.9377 154.466 25.7211C156.848 24.9091 159.988 23.5557 160.043 26.1001C160.043 26.8039 159.447 30.7017 159.393 31.4055C159.23 32.1634 159.068 32.9214 158.96 33.571C158.797 34.5996 158.689 35.4117 158.743 35.8447C159.014 38.4975 160.313 45.2646 159.23 48.1338C158.148 51.0031 156.307 56.9582 157.227 59.7733C158.148 62.5884 157.661 68.3269 157.661 68.3269C157.498 71.6834 159.826 71.8458 162.966 72.9827C162.587 75.6896 163.453 86.1921 166.052 86.9501C168.65 87.7621 171.736 86.4087 172.927 83.9725C173.252 83.3229 173.469 82.6191 173.956 82.1319C174.172 81.9153 174.443 81.7529 174.714 81.6446C174.172 80.3995 173.902 78.9919 173.793 77.5302Z" fill="#122F48"/>
        <path d="M140.553 216.229C141.961 209.137 143.856 205.618 145.48 202.208C145.48 202.208 154.466 191.543 155.116 176.872C155.766 162.255 150.569 149.641 150.569 149.641L113.431 148.071C113.431 148.071 103.848 175.735 103.524 196.361C103.415 201.937 103.361 209.083 103.361 216.229" fill="#3B79B9"/>
        <path d="M171.195 216.338C170.762 211.086 170.599 206.918 170.762 204.319C171.465 194.304 173.306 181.961 170.762 167.939C168.217 153.918 162.749 150.236 162.749 150.236L143.098 149.37L132.758 186.941C132.974 187.537 134.923 200.963 137.413 216.392" fill="#3B79B9"/>
        <path d="M155.712 163.771C155.387 162.85 155.17 162.092 154.9 161.659C154.683 161.172 154.575 160.956 154.521 160.956C154.521 160.956 154.575 161.226 154.791 161.713C155.008 162.201 155.224 162.904 155.495 163.879C155.982 165.774 156.253 168.589 156.091 172.108C155.928 175.627 155.333 179.741 154.087 184.234C153.492 186.454 152.68 188.782 151.543 191.002C150.46 193.275 148.944 195.387 147.374 197.552C146.617 198.635 145.913 199.772 145.263 200.963C144.668 202.1 144.126 203.291 143.639 204.482C142.719 206.864 142.015 209.192 141.474 211.465C141.04 213.198 140.716 214.876 140.391 216.446C140.553 216.446 140.661 216.446 140.824 216.5C141.149 214.93 141.474 213.252 141.907 211.574C143.098 207.134 144.613 202.154 147.808 197.823C149.323 195.657 150.893 193.492 151.976 191.164C153.113 188.89 153.871 186.562 154.521 184.289C155.712 179.795 156.307 175.627 156.415 172.054C156.632 168.535 156.253 165.665 155.712 163.771Z" fill="#122F48"/>
        <path d="M62.4877 91.552C66.4939 93.934 73.6941 96.1536 73.6941 96.1536L81.7605 89.7113C81.7605 89.7113 75.9678 79.1546 74.1272 76.123C73.6399 75.3109 72.882 73.7951 72.6113 72.9289C71.9617 70.6551 70.3376 71.6296 70.0128 73.4161C69.9045 73.9033 70.2834 75.6357 70.5 76.2854C71.2038 78.2884 71.0414 79.696 71.0414 79.696C71.0414 79.696 70.4459 79.2088 68.9842 78.2343C67.4142 77.1516 62.0546 72.7123 59.7809 70.8175C56.4244 68.0024 54.692 69.7348 55.1251 70.8175C55.6665 72.171 64.0577 78.0178 63.7329 79.0464C63.7329 79.0464 62.1088 79.9667 61.8922 80.9953C61.6757 82.0239 61.8922 83.0525 61.8922 83.0525C61.8922 83.0525 60.3764 83.2149 59.9974 84.1894C59.6185 85.1638 60.1057 86.5173 60.1057 86.5173C60.1057 86.5173 58.6981 86.6255 58.5357 87.3835C58.5357 88.1955 59.5102 89.7655 62.4877 91.552Z" fill="#122F48"/>
        <path d="M80.4612 86.7876C80.5695 87.1665 97.785 104.761 97.785 104.761C97.785 104.761 112.131 88.5741 116.516 82.619C120.902 76.664 127.506 69.3555 133.245 68.002C138.929 66.6486 146.887 70.3299 146.887 70.3299L136.764 91.1185L123.987 108.659C123.987 108.659 110.182 125.983 103.632 128.69C97.0813 131.342 90.639 127.499 87.8238 124.629C85.0087 121.706 68.4428 96.5322 68.4428 96.5322L80.4612 86.7876Z" fill="#3B79B9"/>
        <path d="M176.392 74.8778L136.764 68.0024C136.764 68.0024 125.774 87.3835 121.659 107.739C117.545 128.094 113.322 148.504 113.322 148.504L149.432 152.943C153.221 153.431 157.119 153.376 160.909 152.781L164.698 152.185L161.558 139.247C166.81 126.2 170.599 115.047 172.873 105.79C177.799 85.8676 176.392 74.8778 176.392 74.8778Z" fill="#3B79B9"/>
        <path d="M135.843 94.2588C135.843 94.2588 135.627 94.5836 135.194 95.125C134.706 95.7205 134.057 96.4784 133.353 97.3987C131.783 99.2935 129.672 102 127.29 104.924C124.908 107.901 122.742 110.554 121.118 112.395C120.306 113.315 119.602 114.073 119.115 114.56C118.628 115.047 118.357 115.318 118.303 115.318C118.303 115.318 118.519 114.993 118.953 114.452C119.44 113.856 120.089 113.098 120.793 112.178C122.363 110.283 124.475 107.576 126.857 104.599C129.239 101.621 131.404 98.9687 133.028 97.128C133.84 96.2077 134.544 95.4498 135.031 94.9626C135.518 94.4753 135.789 94.2046 135.843 94.2588Z" fill="#122F48"/>
        <path d="M160.963 139.896C160.963 139.95 160.043 140.275 158.473 140.6C156.903 140.925 154.737 141.25 152.301 141.358C149.865 141.466 147.645 141.304 146.075 141.087C144.505 140.871 143.531 140.6 143.531 140.546C143.531 140.492 144.505 140.6 146.075 140.708C147.645 140.816 149.865 140.925 152.247 140.871C154.683 140.762 156.848 140.492 158.418 140.275C159.988 140.004 160.963 139.788 160.963 139.896Z" fill="#122F48"/>
        <path d="M97.4061 115.264C97.2978 115.264 97.1895 112.99 97.2436 110.229C97.2978 107.468 97.4061 105.194 97.5685 105.194C97.6767 105.194 97.785 107.468 97.7309 110.229C97.6767 113.044 97.5143 115.318 97.4061 115.264Z" fill="#122F48"/>
        <g opacity="0.3">
          <path d="M120.144 113.585C124.204 112.773 127.885 110.445 130.43 107.251C132.974 104.003 134.328 99.8346 134.111 95.7202C128.968 101.296 124.583 108.497 120.144 113.585Z" fill="black"/>
        </g>
        <path d="M137.088 25.3422C137.088 25.3422 136.98 25.3964 136.764 25.5046C136.547 25.5588 136.168 25.6129 135.681 25.5588C134.761 25.4505 133.353 24.855 132.487 23.3392C132.054 22.5813 131.891 21.6068 132.054 20.6323C132.216 19.6579 132.757 18.6293 133.515 17.8172C134.273 17.0051 135.356 16.3555 136.547 16.0848C137.738 15.8141 139.037 15.8141 140.283 16.139C140.986 16.3014 141.69 16.572 142.34 16.8969L142.015 17.0051C142.881 14.8397 144.613 13.2697 146.454 12.4576C148.295 11.6456 150.135 11.5914 151.543 11.9163C153.005 12.2411 154.033 12.8366 154.683 13.3238C155.008 13.5404 155.224 13.7569 155.387 13.9193C155.549 14.0817 155.603 14.1359 155.603 14.19C155.603 14.2442 155.278 13.9193 154.575 13.4862C153.925 13.0531 152.896 12.5118 151.489 12.2952C150.081 12.0245 148.349 12.0787 146.617 12.8907C144.938 13.7028 143.26 15.2186 142.448 17.2217L142.34 17.4382L142.123 17.33C141.528 17.0593 140.878 16.7886 140.174 16.6262C138.983 16.3555 137.738 16.3014 136.655 16.572C135.518 16.8427 134.544 17.3841 133.84 18.142C133.136 18.8999 132.649 19.8203 132.487 20.7406C132.324 21.6609 132.433 22.5271 132.812 23.2309C133.57 24.6385 134.869 25.2881 135.735 25.3964C136.601 25.5588 137.088 25.2881 137.088 25.3422Z" fill="#224356"/>
        <path d="M181.86 40.176C181.806 40.176 182.022 39.7429 182.239 38.9309C182.455 38.1188 182.672 36.8737 182.401 35.412C182.185 33.9503 181.427 32.1637 179.911 30.8645C178.449 29.5652 176.175 28.6448 173.847 28.9155L173.469 28.9697L173.577 28.6448C173.793 27.9952 173.902 27.2373 173.956 26.4794C174.064 23.8808 173.144 21.3905 171.682 19.6581C170.22 17.9257 168.271 17.0054 166.593 16.6264C164.915 16.2475 163.507 16.3557 162.533 16.5182C161.558 16.6806 161.071 16.8971 161.071 16.843C161.071 16.843 161.179 16.7888 161.45 16.6806C161.667 16.5723 162.046 16.464 162.533 16.3557C163.507 16.1392 164.969 15.9768 166.701 16.3557C168.434 16.7347 170.491 17.6009 172.061 19.4416C173.631 21.2281 174.66 23.8266 174.497 26.5876C174.443 27.3997 174.335 28.1576 174.118 28.8614L173.847 28.5366C176.338 28.2659 178.666 29.2403 180.236 30.6479C181.806 32.0555 182.564 33.9503 182.726 35.4661C182.943 37.0361 182.672 38.2812 182.401 39.0933C182.239 39.5264 182.13 39.7971 182.022 40.0136C181.968 40.0677 181.914 40.176 181.86 40.176Z" fill="#224356"/>
        <path d="M148.89 24.5845C148.89 24.5845 148.62 24.5303 148.295 24.2596C147.97 23.989 147.483 23.5017 147.158 22.7979C146.833 22.0942 146.617 21.1197 146.779 20.037C146.941 18.9542 147.537 17.8715 148.457 17.0594C149.378 16.2474 150.46 15.7601 151.543 15.5977C152.572 15.4353 153.546 15.5436 154.304 15.7601C155.062 15.9767 155.657 16.2474 155.982 16.5181C156.361 16.7346 156.524 16.897 156.524 16.9512C156.47 17.0053 155.712 16.4639 154.196 16.1391C153.438 15.9767 152.572 15.9226 151.597 16.085C150.623 16.2474 149.648 16.6805 148.782 17.4384C147.97 18.1963 147.429 19.1708 147.212 20.1452C147.05 21.1197 147.212 21.9859 147.483 22.6897C148.078 24.0431 148.999 24.5303 148.89 24.5845Z" fill="#224356"/>
        <path d="M161.342 28.6987L160.692 25.7212C158.473 29.998 157.715 34.9786 158.581 39.6885C159.014 41.9081 159.718 44.236 158.743 46.2391C158.094 47.6467 156.74 48.567 155.982 49.8663C155.008 51.5445 155.712 55.9296 155.495 56.7958C154.954 58.7447 153.005 60.044 152.572 62.4261C152.139 64.8081 155.766 69.139 157.877 70.2759C161.937 63.6171 163.02 55.28 163.94 47.5384C164.752 41.2585 163.291 34.8162 161.342 28.6987Z" fill="#122F48"/>
        <path d="M173.631 44.6691C173.631 44.6691 173.523 44.6691 173.306 44.6691C173.09 44.6691 172.765 44.615 172.332 44.5608C171.52 44.3984 170.22 44.0736 169.029 43.0991C168.434 42.5578 167.892 41.854 167.568 40.9878C167.243 40.1216 167.189 39.0389 167.622 38.0644C168.055 37.0899 168.921 36.2237 170.112 36.0072C170.708 35.8989 171.357 35.953 171.899 36.2779C172.44 36.6027 172.873 37.2523 172.765 37.9561C172.711 38.2809 172.494 38.6058 172.223 38.8223C171.953 39.0389 171.628 39.093 171.303 39.093C170.653 39.093 170.112 38.9306 169.571 38.6599C168.542 38.1727 167.73 37.4689 167.026 36.6568C165.673 35.0869 165.077 33.2462 165.023 31.6762C164.969 30.1063 165.348 28.9152 165.673 28.1032C165.835 27.7242 165.998 27.3994 166.106 27.237C166.214 27.0746 166.322 26.9663 166.322 26.9663C166.377 26.9663 166.106 27.3994 165.835 28.1573C165.565 28.9694 165.24 30.1604 165.348 31.6221C165.456 33.0838 166.052 34.8703 167.351 36.332C168.001 37.0899 168.813 37.7396 169.733 38.1727C170.166 38.3892 170.708 38.5516 171.195 38.5516C171.682 38.5516 172.169 38.2268 172.223 37.7937C172.277 37.3065 172.007 36.8192 171.574 36.6027C171.141 36.332 170.599 36.332 170.112 36.3861C169.138 36.6027 168.325 37.3606 167.947 38.1727C167.568 39.0389 167.568 40.0133 167.892 40.7712C168.163 41.5833 168.65 42.2329 169.192 42.7743C170.274 43.7488 171.52 44.1277 172.332 44.3443C173.144 44.5608 173.631 44.615 173.631 44.6691Z" fill="#224356"/>
        <path d="M194.69 216.338C194.69 216.392 152.788 216.5 101.033 216.5C49.3324 216.5 7.37628 216.446 7.37628 216.338C7.37628 216.284 49.2783 216.175 101.033 216.175C152.734 216.175 194.69 216.284 194.69 216.338Z" fill="#1A2E35"/>
        <path d="M113.485 148.504C129.455 155.109 148.078 157.978 164.644 151.806C163.616 152.239 162.641 152.672 161.613 153.051C160.746 153.376 159.393 153.809 158.473 154.08C143.477 157.978 127.29 154.946 113.485 148.504Z" fill="#122F48"/>
        <path d="M127.452 9.91331C130.321 8.99298 143.91 5.09512 157.119 12.5119C163.128 15.9225 166.864 20.4159 168.921 23.4476C175.417 33.7336 175.905 46.6182 170.112 56.9042C162.804 69.9512 125.016 67.6233 123.175 67.9481C129.996 72.1167 142.61 78.3966 158.635 78.2342C164.428 78.18 172.223 78.0176 180.344 73.1453C187.815 68.6519 191.604 62.5886 192.633 60.7479C196.802 53.5477 197.18 46.8889 197.289 43.9655C197.83 31.3516 191.983 22.04 189.71 18.7376C179.424 3.90411 163.074 1.25139 160.097 0.818298C156.794 0.439339 152.517 0.276928 147.591 1.03485C137.9 2.65895 131.025 7.15232 127.452 9.91331Z" fill="#3B79B9"/>
        <path d="M177.258 31.4057L182.185 30.4312C182.618 30.323 182.942 29.8899 182.834 29.4568C182.726 29.0237 182.293 28.6988 181.86 28.8071L176.933 29.7816C176.5 29.8899 176.175 30.323 176.284 30.7561C176.392 31.1891 176.825 31.4598 177.258 31.4057Z" fill="#122F48"/>
        <path d="M178.124 34.4374L183.051 33.463C183.484 33.3547 183.809 32.9216 183.7 32.4885C183.592 32.0554 183.159 31.7306 182.726 31.8389L177.799 32.8133C177.366 32.9216 177.042 33.3547 177.15 33.7878C177.204 34.2209 177.637 34.4916 178.124 34.4374Z" fill="#122F48"/>
        <path d="M171.79 56.2544C175.647 56.2544 178.774 53.1278 178.774 49.2708C178.774 45.4138 175.647 42.2871 171.79 42.2871C167.933 42.2871 164.807 45.4138 164.807 49.2708C164.807 53.1278 167.933 56.2544 171.79 56.2544Z" fill="#3B79B9"/>
        <path d="M171.52 52.6275C173.373 52.6275 174.876 51.1248 174.876 49.271C174.876 47.4173 173.373 45.9146 171.52 45.9146C169.666 45.9146 168.163 47.4173 168.163 49.271C168.163 51.1248 169.666 52.6275 171.52 52.6275Z" fill="#122F48"/>
        <path opacity="0.43" d="M126.139 27.0652C126.897 25.0505 126.265 22.9475 124.726 22.3682C123.187 21.7889 121.324 22.9525 120.566 24.9672C119.807 26.982 120.44 29.0849 121.979 29.6642C123.518 30.2436 125.381 29.0799 126.139 27.0652Z" fill="white"/>
        <path opacity="0.43" d="M127.181 21.2822C128.019 21.2822 128.697 20.6035 128.697 19.7663C128.697 18.9292 128.019 18.2505 127.181 18.2505C126.344 18.2505 125.666 18.9292 125.666 19.7663C125.666 20.6035 126.344 21.2822 127.181 21.2822Z" fill="white"/>
        <path d="M131.512 72.3335C135.41 73.7411 139.47 74.499 143.585 74.9862C153.817 76.1772 164.265 75.3652 174.389 73.5786L180.452 72.3335C178.449 72.8749 176.446 73.4162 174.443 73.9576C168.38 75.311 162.154 76.2855 155.928 76.4479C147.645 76.6103 139.037 75.9065 131.512 72.3335Z" fill="#122F48"/>
        <path d="M133.57 38.0647C133.732 38.7144 134.382 39.1475 135.085 38.9851C135.735 38.8227 136.168 38.173 136.006 37.4692C135.843 36.8196 135.194 36.3865 134.49 36.5489C133.786 36.7113 133.461 37.4151 133.57 38.0647Z" fill="#224356"/>
        <path d="M148.132 39.8511C148.295 40.5007 147.862 41.2045 147.212 41.3669C146.562 41.5293 145.859 41.0962 145.696 40.4466C145.534 39.7969 145.967 39.0931 146.617 38.9307C147.266 38.7683 147.97 39.2014 148.132 39.8511Z" fill="#224356"/>
        <path d="M144.343 35.9534C145.588 35.2496 147.104 35.0331 148.457 35.412C149.053 35.5744 149.648 35.8993 150.135 36.2782C150.677 36.6572 151.056 37.1444 151.489 37.6316C151.597 37.7941 151.868 37.6317 151.814 37.4692C151.381 36.1158 149.973 35.1413 148.674 34.7624C147.104 34.3293 145.426 34.6 144.126 35.5744C143.856 35.7369 144.072 36.0617 144.343 35.9534Z" fill="#224356"/>
        <path d="M132.433 34.1127C133.407 33.3006 134.706 32.7592 135.952 33.0841C136.547 33.2465 137.034 33.5172 137.467 33.9503C137.901 34.3834 138.225 34.8706 138.658 35.3578C138.767 35.4661 138.929 35.3578 138.929 35.2495C138.983 33.7878 137.413 32.7051 136.114 32.3803C134.598 32.0555 132.92 32.5968 132.108 33.9503C132 34.1127 132.27 34.2751 132.433 34.1127Z" fill="#224356"/>
        <path d="M139.579 38.3354C139.579 38.3354 136.06 44.3988 136.818 45.4274C138.063 47.1056 140.878 46.456 140.878 46.456" stroke="#526674" stroke-width="2.1633" stroke-miterlimit="10" stroke-linejoin="round"/>
        <path d="M146.238 49.5959L136.331 49.3252C136.331 49.3252 136.385 55.0637 139.633 55.2261C145.967 55.4968 146.238 49.5959 146.238 49.5959Z" fill="white"/>
        <path d="M190.089 200.692C189.872 200.53 189.71 200.422 189.493 200.259C185.27 197.119 181.806 193.221 179.261 188.728C177.042 184.776 175.58 181.149 174.876 177.792C178.395 179.308 181.535 180.12 183.755 180.499C187.923 181.257 192.254 180.066 195.286 177.359C199.075 174.003 200.645 168.806 199.508 163.446C198.48 158.574 195.448 154.784 191.55 153.485C188.789 152.564 184.404 152.456 179.099 157.22C174.281 161.551 171.628 166.532 171.249 172.162C168.434 170.43 165.619 168.156 162.749 165.287C153.059 155.596 147.158 138.11 145.75 116.022C144.776 117.971 143.91 119.812 143.098 121.652C144.235 143.253 149.865 156.571 160.313 167.56C163.94 171.35 167.73 174.111 171.303 176.06C171.736 180.445 173.414 185.155 176.392 190.406C177.854 192.951 179.532 195.278 181.481 197.444C183.159 199.285 185 201.017 187.003 202.533L190.089 200.692ZM174.497 174.003C174.443 168.589 176.663 163.879 181.318 159.765C184.567 156.841 187.761 155.813 190.522 156.733C191.713 157.112 192.795 157.87 193.716 158.898C194.961 160.252 195.881 162.093 196.314 164.258C197.18 168.427 195.989 172.487 193.12 175.031C190.846 177.088 187.598 177.955 184.404 177.359C182.022 176.872 178.449 175.952 174.497 174.003Z" fill="#122F48"/>
        <path d="M171.844 179.254C171.79 179.254 171.628 178.767 171.465 177.9C171.303 177.034 171.195 175.897 171.141 174.544C171.086 173.245 171.141 172.054 171.249 171.187C171.357 170.321 171.52 169.834 171.574 169.834C171.628 169.834 171.52 170.375 171.465 171.187C171.411 172.054 171.411 173.19 171.465 174.49C171.52 175.789 171.628 176.98 171.682 177.792C171.79 178.712 171.898 179.2 171.844 179.254Z" fill="white"/>
        <path d="M175.634 180.553C175.58 180.553 175.255 180.066 174.93 179.2C174.605 178.279 174.281 176.98 174.226 175.518C174.172 174.057 174.281 172.757 174.443 171.783C174.605 170.863 174.822 170.267 174.876 170.321C174.93 170.321 174.768 170.917 174.66 171.837C174.551 172.757 174.443 174.057 174.551 175.518C174.66 176.926 174.876 178.225 175.147 179.146C175.417 179.958 175.688 180.499 175.634 180.553Z" fill="white"/>
        <path d="M175.255 162.905C175.255 162.905 175.309 162.688 175.526 162.363C175.688 162.038 176.013 161.605 176.392 161.064C177.204 159.981 178.449 158.628 180.019 157.328C181.643 156.083 183.267 155.217 184.512 154.676C185.108 154.405 185.649 154.243 185.974 154.134C186.353 154.026 186.515 153.972 186.515 154.026C186.515 154.08 185.758 154.351 184.567 154.892C183.376 155.434 181.751 156.354 180.181 157.599C178.612 158.844 177.366 160.198 176.5 161.226C175.742 162.255 175.309 162.959 175.255 162.905Z" fill="white"/>
        <path d="M160.8 164.745C160.8 164.8 160.259 164.421 159.501 163.717C158.743 163.013 157.823 161.984 156.957 160.739C156.091 159.44 155.495 158.195 155.116 157.22C154.737 156.3 154.629 155.65 154.683 155.65C154.737 155.65 154.954 156.192 155.333 157.112C155.712 158.032 156.361 159.278 157.227 160.523C158.094 161.768 158.96 162.851 159.664 163.554C160.367 164.312 160.855 164.691 160.8 164.745Z" fill="white"/>
        <path d="M179.748 151.049H147.05C143.422 151.049 140.445 148.071 140.445 144.444V78.8298C140.445 75.2026 143.422 72.2251 147.05 72.2251H179.748C183.376 72.2251 186.353 75.2026 186.353 78.8298V144.39C186.353 148.071 183.43 151.049 179.748 151.049Z" fill="#BFC5CA"/>
        <path d="M148.565 146.393L178.882 146.122C180.939 146.122 182.564 144.444 182.509 142.386L181.968 80.4537C181.968 78.3965 180.29 76.7724 178.233 76.8265L147.916 77.0972C145.859 77.0972 144.235 78.7754 144.289 80.8326L144.83 142.765C144.83 144.823 146.508 146.447 148.565 146.393Z" fill="#98A4AC"/>
        <path d="M176.987 102.163H163.778V105.465H176.987V102.163Z" fill="#122F48"/>
        <path d="M176.987 107.685H163.778V110.987H176.987V107.685Z" fill="#122F48"/>
        <path d="M157.119 106.44C158.584 106.44 159.772 105.252 159.772 103.787C159.772 102.322 158.584 101.134 157.119 101.134C155.654 101.134 154.466 102.322 154.466 103.787C154.466 105.252 155.654 106.44 157.119 106.44Z" fill="#122F48"/>
        <path d="M79.8115 84.6766C77.7002 85.9759 75.1558 87.8165 72.6113 90.3068C70.7165 92.2016 69.2007 94.0423 68.0638 95.7205C67.9555 95.8288 67.4683 96.316 67.4142 97.0198C67.36 97.507 67.4683 97.9943 67.739 98.4274L67.7931 98.4815C68.551 99.5642 70.0669 99.7267 71.0413 98.8063C72.2865 97.2364 73.6941 95.6122 75.3723 93.934C77.9167 91.3896 80.407 89.3324 82.6808 87.7082C82.7349 87.6541 83.7094 86.3007 83.0598 85.1097C82.4642 83.9728 80.8401 83.7021 79.8115 84.6766Z" fill="#122F48"/>
      </svg>
    );
}

export default SuccessVerifyEmail;